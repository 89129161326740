import { FC } from "react";

import {
  LS_KC_TOKEN_EXP,
  REFERRER_SESSION_STORAGE_ITEM_NAME,
  useAuthLogin,
  useIsAuthenticated,
} from "@keepeek/commons";
import {
  KButtonProps,
  KKeycloakAutomaticAuth,
  KKeycloakCredentialAuthButton,
  KKeycloakGuestAuthButton,
  KLandingHeader,
  KLandingRequestAccount,
  LandingPage,
} from "@keepeek/refront-components";
import { PageOverrideKey } from "@keepeek/refront-customers";
import { LinkProps } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/plugin/utc";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { KIcon } from "../components/common/KIcon";
import {
  isKeycloakAutomaticConnectAvailable,
  isKeycloakConnectWithCredentialsEnabled,
  isKeycloakConnectWithGuestAvailable,
} from "../components/landingPage/utils";
import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import { PagesPath } from "../containers/App/utils";
import LoadingAuth from "../containers/auth/LoadingAuth";
import EditionModal from "../containers/FrontEdit/EditionModal";
import ImageOptim from "../containers/ImageOptim";
import { handleChangeLanguage } from "../containers/KLanguageSwitcher/utils";
import { getCustomerOverrideComponent } from "../lib/overrides";
import { Global } from "../models/configuration/global";
import { DisplayMode, Landing as LandingLayout } from "../models/configuration/layouts/landing";
import { Landing as LandingPageModel } from "../models/configuration/pages/landing";
import { Key } from "../models/configuration/theme";
import { useAuthProps } from "../providers/auth/hooks/useAuthProps";
import { useAutoGuestAuth } from "../providers/auth/hooks/useAutoGuestAuth";
import { useAutoIdpAuth } from "../providers/auth/hooks/useAutoIdpAuth";
import { useConfiguration } from "../providers/config/hooks";
import { useBackdoors } from "../providers/config/hooks/useBackdoors";
import { useHeaderMetas } from "../providers/config/hooks/useHeaderMetas";
import { useImageOptim } from "../providers/config/hooks/useImageOptim";
import { useUILocals } from "../providers/config/hooks/useUILocals";
import { configSectionThemeAssetSelector } from "../providers/config/selectors";
import { frontEditState } from "../providers/frontEdit/atoms/frontEdit";
import useFrontEditLabelWrapper from "../providers/frontEdit/hooks/useFrontEditLabelWrapper";
import { getCurrentContentEditsSelector } from "../providers/frontEdit/selectors/currentContentEdits";
import { useCurrentLocale } from "../providers/i18n/hooks";

const Landing: FC<React.PropsWithChildren<unknown>> = function Login() {
  const { pathname, asPath, query, push } = useRouter();
  const resolvedLocales = useUILocals();
  const { t } = useTranslation();
  useHeaderMetas({ title: t("page.connection.title") as string });
  const { loading: loadingAutoIdpLogin } = useAutoIdpAuth();
  const { loading: loadingAutoGuestLogin } = useAutoGuestAuth();
  const { loginUrl, guestLoginUrl } = useAuthLogin(useAuthProps());
  const { shouldOptimize, loading: loadingOptimImage } = useImageOptim();
  const { loading, isAuthenticated } = useIsAuthenticated();
  const { keycloakConfiguration } = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL) ?? {
    keycloakConfiguration: { displayConnectWithCredentials: true },
  };
  const {
    displayAppTitle,
    logoCentered,
    logoMaxHeight,
    backgroundLogin,
    backgroundVideoLogin,
    logoLogin,
    panelPosition,
    panelWidth,
    buttonsStyle,
  } = useConfiguration<LandingLayout>(CONFIGURATION_SECTION_PATH.LAYOUT_LANDING) ?? {
    displayAppTitle: false,
    logoCentered: false,
    logoMaxHeight: 5,
  };

  const { FrontEditComponentsLabelWrapper } = useFrontEditLabelWrapper();

  const { helpLinkHref, requestAccountHref } = useConfiguration<LandingPageModel>(
    CONFIGURATION_SECTION_PATH.PAGES_LANDING,
  ) ?? { helpLinkHref: "", requestAccountHref: "" };
  const currentLocale = useCurrentLocale();
  const getRequestAccountHref = () => {
    if (currentLocale && requestAccountHref?.includes("{{currentLocale}}")) {
      return requestAccountHref.replace("{{currentLocale}}", currentLocale);
    }
    return requestAccountHref;
  };

  const { helpLinkCentered } = useConfiguration<LandingLayout>(
    CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
  ) ?? { helpLinkCentered: false };
  const { displayConnectWithCredentials } = useBackdoors();

  if (document.referrer) {
    sessionStorage.setItem(REFERRER_SESSION_STORAGE_ITEM_NAME, document.referrer);
  }

  const frontEdit = useRecoilValue(frontEditState);
  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
    }),
  );
  const [background] = useRecoilValue<string[]>(
    configSectionThemeAssetSelector(Key.ImageBackgroundLogin),
  );
  let backgroundSrc: string = background;
  if (backgroundLogin && backgroundLogin.url) {
    backgroundSrc = backgroundLogin.url;
  }
  if (frontEdit && currentContentEdit) {
    backgroundSrc = currentContentEdit.data.backgroundLogin.url;
  }
  let backgroundVideoSrc: string | undefined = backgroundVideoLogin?.resource?.url;
  if (frontEdit && currentContentEdit) {
    backgroundVideoSrc = currentContentEdit.data.backgroundVideoLogin?.resource?.url;
  }

  let autoPlay: boolean | undefined = backgroundVideoLogin?.autoPlay;
  if (frontEdit && currentContentEdit) {
    autoPlay = currentContentEdit.data.backgroundVideoLogin?.autoPlay;
  }

  let loop: boolean | undefined = backgroundVideoLogin?.loop;
  if (frontEdit && currentContentEdit) {
    loop = currentContentEdit.data.backgroundVideoLogin?.loop;
  }

  let controls: boolean | undefined = backgroundVideoLogin?.controls;
  if (frontEdit && currentContentEdit) {
    controls = currentContentEdit.data.backgroundVideoLogin?.controls;
  }

  const [logoLoginOld] = useRecoilValue<string[]>(
    configSectionThemeAssetSelector(Key.ImageLogoLogin),
  );

  let logoLoginSrc: string = logoLoginOld;
  if (logoLogin && logoLogin.url) {
    logoLoginSrc = logoLogin.url;
  }

  if (frontEdit && currentContentEdit) {
    logoLoginSrc = currentContentEdit.data.logoLogin.url;
  }

  if (loadingAutoGuestLogin || loadingAutoIdpLogin) {
    return <LoadingAuth forceShow />;
  }

  // Redirect to home if the user is authenticated and we're not in edit mode
  if (isAuthenticated && !frontEdit) {
    push(PagesPath.HOME_PAGE);
    return null;
  }

  // Redirect to redirect page if the user can have a token
  const expiration = (): number | undefined => {
    const getExpiration = localStorage.getItem(LS_KC_TOKEN_EXP);
    return isNaN(Number(getExpiration)) ? undefined : Number(getExpiration) * 1000;
  };
  if (expiration() && loading && !frontEdit) {
    const isStillValid = dayjs().isBefore(dayjs(expiration()));
    if (isStillValid) {
      return <LoadingAuth forceShow />;
    }
  }

  let mainAuthMethod: JSX.Element | null = null;
  const bottomComponents: { element: JSX.Element; position: number }[] = [];
  const loginUrlComputed = loginUrl();
  const componentsProps: { connexionButton: KButtonProps; helpButton: LinkProps } = {
    connexionButton: {
      style: { fontWeight: "bold" },
    },
    helpButton: { textTransform: "uppercase" },
  };
  const idps: Array<{ key: string; name: string; link: string }> = [];
  keycloakConfiguration?.idp?.forEach((idp) =>
    idps.push({
      key: idp.key ?? "",
      name: idp.name ?? "",
      link: loginUrl(idp.key) ?? "",
    }),
  );
  if (isKeycloakAutomaticConnectAvailable(keycloakConfiguration)) {
    mainAuthMethod = (
      <KKeycloakAutomaticAuth
        helpLinkHref={helpLinkHref}
        helpLinkCentered={helpLinkCentered}
        icon={<KIcon>flash_auto</KIcon>}
        idps={idps}
        componentsProps={componentsProps}
        EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
        adminButtonStyle={buttonsStyle?.idpButtonStyle}
      />
    );
    if (
      isKeycloakConnectWithCredentialsEnabled({
        ...keycloakConfiguration,
        displayConnectWithCredentials:
          keycloakConfiguration?.displayConnectWithCredentials || displayConnectWithCredentials,
      }) &&
      loginUrlComputed
    ) {
      bottomComponents.push({
        element: (
          <KKeycloakCredentialAuthButton
            loginUrl={loginUrlComputed}
            icon={<KIcon>lock_open</KIcon>}
            EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
            adminButtonStyle={buttonsStyle?.keycloakCredentialAuthButtonStyle}
            defaultDisplayMode={DisplayMode.Headband}
          />
        ),
        position: buttonsStyle?.keycloakCredentialAuthButtonStyle?.position ?? 0,
      });
    }
  } else if (loginUrlComputed) {
    mainAuthMethod = (
      <KKeycloakCredentialAuthButton
        loginUrl={loginUrlComputed}
        icon={<KIcon>lock_open</KIcon>}
        EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
        adminButtonStyle={buttonsStyle?.keycloakCredentialAuthButtonStyle}
        defaultDisplayMode={DisplayMode.Button}
      />
    );
  }
  if (isKeycloakConnectWithGuestAvailable(keycloakConfiguration) && guestLoginUrl) {
    bottomComponents.push({
      element: (
        <KKeycloakGuestAuthButton
          loginUrl={guestLoginUrl}
          icon={<KIcon>assignment_ind</KIcon>}
          adminButtonStyle={buttonsStyle?.keycloakGuestAuthButtonStyle}
          EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
        />
      ),
      position: buttonsStyle?.keycloakGuestAuthButtonStyle?.position ?? 1,
    });
  }
  bottomComponents.push({
    element: (
      <KLandingRequestAccount
        href={getRequestAccountHref()}
        icon={<KIcon>person_add</KIcon>}
        EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
        adminButtonStyle={buttonsStyle?.keycloakAccountCreationButtonStyle}
      />
    ),
    position: buttonsStyle?.keycloakAccountCreationButtonStyle?.position ?? 2,
  });

  const onChangeLanguage = (locale) =>
    handleChangeLanguage(locale, () => push({ pathname, query }, asPath, { locale }));

  return (
    <>
      <LandingPage
        header={
          <KLandingHeader
            displayAppTitle={displayAppTitle}
            logoCentered={logoCentered}
            logoMaxHeight={logoMaxHeight}
            logoLoginSrc={logoLoginSrc ?? ""}
            appTitle={t("landing.app.title")}
            loadingOptimImage={loadingOptimImage}
            locales={resolvedLocales}
            onLocaleChange={onChangeLanguage}
            imageComponent={{
              active: shouldOptimize,
              component: (src) =>
                src && (
                  <ImageOptim
                    src={src}
                    alt={""}
                    height={"40"}
                    width={"100"}
                    style={{
                      objectFit: "contain",
                      objectPosition: "0%",
                      width: "unset",
                      height: "100%",
                    }}
                    priority
                    quality={100}
                    unoptimized
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={src}
                      alt={""}
                      style={{
                        width: "100%",
                        objectPosition: "center center",
                      }}
                    />
                  </ImageOptim>
                ),
            }}
            EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
          />
        }
        mainAuthMethod={mainAuthMethod}
        bottomComponents={bottomComponents
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map((authButton) => authButton.element)}
        backgroundSrc={backgroundSrc ?? ""}
        backgroundVideo={{
          ...backgroundVideoLogin,
          autoPlay,
          loop,
          controls,
          resource: { ...backgroundVideoLogin?.resource, url: backgroundVideoSrc },
        }}
        logoLoginSrc={logoLoginSrc ?? ""}
        loadingOptimImage={loadingOptimImage}
        panelPosition={panelPosition}
        panelWidth={panelWidth}
        imageComponent={{
          active: shouldOptimize,
          component: (src) =>
            src && (
              <ImageOptim
                src={src}
                alt={t("landing.page.subtitle")}
                sizes="100vw"
                fill
                style={{ objectFit: "cover", objectPosition: "50% 50%" }}
                priority
              >
                {null}
              </ImageOptim>
            ),
        }}
        EditableSanitizeHtml={FrontEditComponentsLabelWrapper}
      />
      <EditionModal />
    </>
  );
};

export default getCustomerOverrideComponent(PageOverrideKey.Login, Landing);
