export interface Landing {
    backgroundLogin?:      BackgroundLogin;
    backgroundVideoLogin?: BackgroundVideoLogin;
    buttonsStyle?:         ButtonsStyle;
    displayAppTitle?:      boolean;
    fullHeightPanel?:      boolean;
    helpLinkCentered?:     boolean;
    logoCentered?:         boolean;
    logoLogin?:            BackgroundLogin;
    logoMaxHeight?:        number;
    panelPosition?:        PanelPosition;
    panelWidth?:           string;
}

export interface BackgroundLogin {
    id?:  string;
    url?: string;
}

export interface BackgroundVideoLogin {
    autoPlay?: boolean;
    controls?: boolean;
    loop?:     boolean;
    resource?: BackgroundLogin;
}

export interface ButtonsStyle {
    idpButtonStyle?:                     IdpButtonStyle;
    keycloakAccountCreationButtonStyle?: KeycloakAccountCreationButtonStyle;
    keycloakCredentialAuthButtonStyle?:  KeycloakCredentialAuthButtonStyle;
    keycloakGuestAuthButtonStyle?:       KeycloakGuestAuthButtonStyle;
}

export interface IdpButtonStyle {
    backgroundColor?:        string;
    backgroundColorOnHover?: string;
    borderColor?:            string;
    borderRadius?:           string;
    iconPosition?:           IconPosition;
    textColor?:              string;
    textColorOnHover?:       string;
    verticalPadding?:        string;
    width?:                  string;
}

export enum IconPosition {
    Left = "LEFT",
    NoIcon = "NO_ICON",
    Right = "RIGHT",
}

export interface KeycloakAccountCreationButtonStyle {
    backgroundColor?:        string;
    backgroundColorOnHover?: string;
    borderColor?:            string;
    borderRadius?:           string;
    displayMode?:            DisplayMode;
    horizontalPadding?:      string;
    iconPosition?:           IconPosition;
    position?:               number;
    textColor?:              string;
    textColorOnHover?:       string;
    verticalPadding?:        string;
    width?:                  string;
}

export enum DisplayMode {
    Button = "BUTTON",
    Headband = "HEADBAND",
}

export interface KeycloakCredentialAuthButtonStyle {
    backgroundColor?:        string;
    backgroundColorOnHover?: string;
    borderColor?:            string;
    borderRadius?:           string;
    displayMode?:            DisplayMode;
    horizontalPadding?:      string;
    iconPosition?:           IconPosition;
    position?:               number;
    textColor?:              string;
    textColorOnHover?:       string;
    verticalPadding?:        string;
    width?:                  string;
}

export interface KeycloakGuestAuthButtonStyle {
    backgroundColor?:        string;
    backgroundColorOnHover?: string;
    borderColor?:            string;
    borderRadius?:           string;
    displayMode?:            DisplayMode;
    horizontalPadding?:      string;
    iconPosition?:           IconPosition;
    position?:               number;
    textColor?:              string;
    textColorOnHover?:       string;
    verticalPadding?:        string;
    width?:                  string;
}

export enum PanelPosition {
    Center = "center",
    Left = "left",
    Right = "right",
}
